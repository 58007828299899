@import "~@/styles/variables/variables.scss";





















































































































































































































































































::v-deep .el-form {
	margin-top: 14px;
	display: flex;
	flex-wrap: wrap;
	.el-form-item {
		flex: 0 0 calc((100% - 30px) / 2);
		margin-right: 0;
		&:nth-child(2n-1) {
			margin-right: 30px;
		}
		&.col-12 {
			flex: 0 0 100%;
			margin-right: 0;
		}
		.el-form-item__label {
			line-height: 14px;
			height: 14px;
			margin-bottom: 10px;
			font-weight: 600;
		}
		.el-select {
			width: 100%;
		}
	}
}
