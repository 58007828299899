@import "~@/styles/variables/variables.scss";




















































































































































































































































.page-main-left {
	padding-left: 0;
	padding-right: 0;
	display: flex;
	flex-direction: column;
	::v-deep {
		.el-button {
			margin: 20px;
		}
	}
}

::v-deep .el-tree {
	flex: 1;
	margin-top: 40px;
	.custom_tree {
		flex: 1;
		display: flex;
		justify-content: space-between;

		.left_text {
			display: flex;
			align-items: center;
			p {
				color: #666666;
				font-size: 14px;
			}
			img {
				margin-left: 2px;
				position: relative;
				top: -1px;
				width: 12px;
			}
			&._disabled {
				cursor: not-allowed;
				color: #c9c9c9;
				// p {
				//   color: #c9c9c9;
				// }
			}
		}
	}
	.is-current {
		& > .el-tree-node__content {
			& > .el-tree-node__label {
				color: #2080f7;
			}
		}
	}
	.el-tree-node__content {
		line-height: 34px;
		height: 34px;
		padding-left: 17px;
		.el-checkbox__inner {
			border-radius: 0;
		}
		.el-tree-node__expand-icon {
			&:not(.is-leaf) {
				color: #666;
			}
		}
		&:hover {
			.el-icon-more {
				display: block !important;
				color: #2f87fe;
			}
		}
	}
}

.page-main-right {
	display: flex;
	flex-direction: column;
	.pale-title {
		margin-top: 40px;
		font-size: 14px;
		color: #ababab;
		line-height: 21px;
	}
	.staff-list {
		margin-top: 30px;
		flex: 1;
		overflow-y: scroll;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}
}
