@import "~@/styles/variables/variables.scss";





















































































































































































::v-deep .el-form-item__content {
	display: flex;
	.el-button {
		margin-left: 10px;
		line-height: 32px;
		height: 32px;
		padding: 0 10px;

		&.is-plain {
			background: transparent !important;
			&.el-button--info {
				color: #4c4c4c !important;
			}
			&.el-button--primary {
				color: #2f87fe !important;
			}
		}
	}
}
