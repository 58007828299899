@import "~@/styles/variables/variables.scss";



















































































.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.staff-item {
	position: relative;
	flex: 0 0 297px;
	height: 151px;
	margin: 0 25px 24px 0;
	box-sizing: border-box;
	border-radius: 8px;
	overflow: hidden;

	&.onDuty {
		background: url('./onDuty.png') no-repeat;
		background-size: cover;
	}
	&.resign {
		background: url('./resigned.png') no-repeat;
		background-size: cover;
		.top .top-right {
			margin-left: 24px;
			color: #7e7f7e;
		}
		.bottom {
			color: #7e7f7e;
			.bottom-block.bottom-block_left {
				border-right: 1px solid #7e7f7e;
			}
		}
	}
	&:hover .staff-mask {
		// display: block;
		opacity: 1;
	}
	.staff-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: opacity ease-in 0.2s;
		// display: none;
		opacity: 0;
		::v-deep .el-button {
			width: 100%;
			height: 50px;
			background: transparent;
			font-size: 14px;
			color: #ffffff;
			line-height: 21px;
			border: none;
		}
		.mask-top {
			border-radius: 8px;
			overflow: hidden;
			background: rgba(0, 0, 0, 0.55);
		}
		.mask-bottom {
			border-radius: 8px;
			overflow: hidden;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			background: rgba(0, 0, 0, 0.55);
			.split-line {
				flex: 0 0 1px;
				width: 1px;
				height: 14px;
				background: #ffffff;
			}
			::v-deep .el-button {
				flex: 1;
			}
		}
	}
	.top {
		display: flex;
		align-items: center;
		margin: 24px 0 37px 24px;
		.avatar {
			flex: 0 0 50px;
			width: 50px;
			height: 50px;
			border-radius: 50%;
		}
		.top-right {
			margin-left: 24px;
			color: #3c372b;
			flex: 1;
			max-width: calc(100% - 98px);

			.staff-name {
				width: 100%;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
			}
			.staff-no {
				width: 100%;
				margin-top: 10px;
				font-size: 14px;
				line-height: 24px;
			}
		}
	}
	.bottom {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #3c372b;
		line-height: 16px;
		.icon {
			margin-right: 10px;
			width: 16px;
			height: 16px;
			img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.bottom-block {
			padding: 0 12px;
			box-sizing: border-box;
			flex: 0 0 50%;
			text-align: center;

			&.bottom-block_left {
				border-right: 1px solid #3c372b;
			}
		}
	}
}
