@import "~@/styles/variables/variables.scss";



































































































































































































































































































































































.split-line {
	flex: 1;
	border: 0.5px dashed #ccc;
	height: 0;
	transform: scaleY(0.5);
	margin-bottom: 20px;
}
::v-deep .el-form {
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;
	.pale-title {
		flex: 0 0 100%;
		font-size: 14px;
		color: #ababab;
		line-height: 21px;
		margin-bottom: 20px;
	}
	.el-form-item {
		flex: 0 0 calc((100% - 30px) / 2);
		margin-right: 0;
		margin-bottom: 20px;
		&:nth-child(2n),
		&.margin-right-20px {
			margin-right: 30px;
		}
		&.original {
			margin-right: 0;
		}
		&.col-12 {
			flex: 0 0 100%;
			margin-right: 0;
		}
		.el-form-item__label {
			line-height: 14px;
			height: 14px;
			margin-bottom: 10px;
			font-weight: 600;
		}
		.el-select {
			width: 100%;
		}
		.el-input.is-disabled .el-input__icon {
			display: none;
		}
	}
}
