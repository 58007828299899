@import "~@/styles/variables/variables.scss";


























































.department-info {
	margin-top: 11px;
	display: flex;
	flex-wrap: wrap;
	flex-flow: flow;
	flex-direction: row;
	.info-item {
		margin-top: 20px;
		flex: 0 0 33.33%;
		display: flex;
		.label {
			width: 70px;
			// margin-right: 10px;
			font-size: 14px;
			color: #666666;
			line-height: 21px;
		}
		.value {
			font-size: 14px;
			color: #333333;
			line-height: 21px;
		}
	}
}
